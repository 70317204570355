import { render, staticRenderFns } from "./PhoneLogin.vue?vue&type=template&id=d233dd48&scoped=true&"
import script from "./PhoneLogin.vue?vue&type=script&lang=ts&"
export * from "./PhoneLogin.vue?vue&type=script&lang=ts&"
import style0 from "./PhoneLogin.vue?vue&type=style&index=0&id=d233dd48&lang=less&scoped=true&"
import style1 from "./PhoneLogin.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d233dd48",
  null
  
)

export default component.exports