














































import { getLocalCorpDetail, saveLoginToken, sendPhoneCode, T_SendCodeParams, verifyPhoneCode } from '@/api/login'
import { defineComponent } from '@vue/composition-api'
import { Form } from 'vant'
import { APP_ENV } from '@/config/env'
import { getWXAuthUrl } from '@/shared/wx'

const SendCodeIntervalTime = 60 // 60s 短信倒计时

export default defineComponent({
  data() {
    return {
      corpName: this.$route.query.corpName,

      loginParams: {
        number: '',
        areaCode: '+86',
        verifyCode: ''
      },

      sendCode: {
        isSending: false,
        intervalTime: SendCodeIntervalTime,
        intervalId: 0,
        isSended: false
      },

      riversAppId: this.$route.params.riversAppId || ''
    }
  },
  computed: {
    Rules() {
      return getRules(this.loginParams as T_SendCodeParams)
    }
  },
  watch: {
  },
  destroyed() {
    clearInterval(this.sendCode.intervalId)
  },
  methods: {
    formatCorpName(name: any): string {
      return (name || '').replace('易有料北京', '工深之星')
    },
    sendVerifyCode() {
      this.checkFormValidate('number').then(() => {
        return sendPhoneCode(this.riversAppId, this.loginParams)
      }).then((code: string) => {
        if (APP_ENV === 'test') {
          this.$notify({ type: 'primary', message: `测试环境 - 验证码为: ${code}` })
        }
        this.sendCode.isSending = true
        this.sendCode.isSended = true
        this.setSendCodeTimer()
      }).catch(err => {
        err.message && this.$notify({ type: 'danger', message: err.message })
      })
    },
    setSendCodeTimer() {
      this.sendCode.intervalTime = SendCodeIntervalTime
      this.intervalId = setInterval(() => {
        this.sendCode.intervalTime--
        const { intervalId, intervalTime } = this.sendCode
        if (intervalTime === 0) {
          clearInterval(intervalId)
          this.sendCode.isSending = false
        }
      }, 1000) // 1s
    },
    onSubmit() {
      if (!this.sendCode.isSended) {
        return this.$notify({ type: 'danger', message: '请先发送验证码' })
      }
      verifyPhoneCode(this.riversAppId, this.loginParams).then(res => {
        saveLoginToken(res)
        if (process.env.NODE_ENV === 'development') {
          return this.$router.push(`/${this.riversAppId}`)
        }
        const redirectUrl = `${location.protocol}//${location.host}/${this.riversAppId}/login-auth-wx`
        const appId = getLocalCorpDetail()?.appId as string
        const authUrl = getWXAuthUrl(appId, redirectUrl)
        window.location.href = authUrl
        // this.$router.push(`/${this.riversAppId}`)
      }).catch(err => {
        this.$notify({ type: 'danger', message: err.message })
      })
    },
    checkFormValidate(key: string) {
      const formRef = this.$refs.loginForm as Form
      return formRef.validate(key)
    }
  }
})

function getRules(loginParams: T_SendCodeParams) {
  const phoneValidator = (value: string) => /^1[3-9]\d{9}$/.test(value)

  const phoneValidate = loginParams.number && phoneValidator(loginParams.number)

  const number = [
    { required: true, message: '' },
    { validator: phoneValidator, trigger: 'onBlur' }
  ]

  const verifyCode = [
    { required: phoneValidate, message: '', trigger: 'onSubmit' }
  ]

  return {
    number,
    verifyCode
  }
}
